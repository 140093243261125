import { css } from '@emotion/react'
import type { PolicyOptionalGuarantee } from '@orus.eu/dimensions'
import {
  ContentContainerAppClient,
  NotFoundError,
  PageTitle,
  ResponsiveCard,
  Text,
  colorTokens,
  spacing,
  useCrash,
  useScreenType,
  useTranslate,
} from '@orus.eu/pharaoh'
import { useParams } from '@tanstack/react-router'
import { memo, type ReactElement } from 'react'
import { trpcReact } from '../../client'
import { useShowIllustrations } from '../../lib/use-show-illustrations'
import { GuaranteeList } from '../organisms/guarantee/guarantee-list'
import { OptionalGuaranteeList } from '../organisms/guarantee/option-list'

const ProductGuaranteesPage = memo(function ProductGuaranteesPage(): ReactElement {
  const translate = useTranslate()
  const { productName, subscriptionId } = useParams({
    from: '/_customer/product-guarantees/$subscriptionId/$productName',
  })
  const screenType = useScreenType()
  const crash = useCrash()
  const showIllustrations = useShowIllustrations()

  const [contract] = trpcReact.contracts.getMyContract.useSuspenseQuery(subscriptionId)

  const relevantContract = contract[0]

  const productInformation = relevantContract.productsInformation.find((product) => productName === product.name)

  if (!productInformation) {
    crash(new NotFoundError())
    return <></>
  }

  const activeOptions: PolicyOptionalGuarantee[] = productInformation.optionalGuarantees.filter(
    (optionalGuarantee) => optionalGuarantee.isActive,
  )

  return (
    <>
      <PageTitle
        title={
          productInformation.guarantees.length > 1
            ? translate('guarantees_of_product', { productName })
            : translate('guarantee_of_product', { productName })
        }
      />

      <ContentContainerAppClient>
        {screenType === 'desktop' ? (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: ${spacing[30]};
            `}
          >
            <Text variant="subtitle">{relevantContract.activity.displayName}</Text>
            <Text variant="body2">{relevantContract.companyAddress}</Text>
          </div>
        ) : null}

        <ResponsiveCard
          css={css`
            display: flex;
            flex-direction: column;
            gap: ${spacing[70]};
            border: 1px solid ${colorTokens['color-stroke-base']};
            margin-top: ${screenType === 'desktop' ? 0 : `-${spacing[70]}`};
          `}
        >
          {productInformation.guarantees.length > 1 ? (
            <Text variant="h6">
              {translate('the_x_guarantees_included', { count: productInformation.guarantees.length })}
            </Text>
          ) : (
            <Text variant="h6">{translate('the_guarantee_included')}</Text>
          )}
          <GuaranteeList guarantees={productInformation.guarantees} />

          {activeOptions.length > 0 ? (
            <>
              <Text variant="h6">{translate('the_active_options')}</Text>
              <OptionalGuaranteeList optionalGuarantees={activeOptions} useIllustrations={showIllustrations} />
            </>
          ) : (
            <></>
          )}
        </ResponsiveCard>
      </ContentContainerAppClient>
    </>
  )
})

export default ProductGuaranteesPage
